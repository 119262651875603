/* eslint react/destructuring-assignment: 0 */
import React from 'react'
import { graphql } from 'gatsby'
import Post from './post'

const widgetOptions = {
  filters: true,
  items: 5,
}

const PostDevice = props => <Post {...props} widgetOptions={widgetOptions} />

export const pageQueryDevice = graphql`
  query DevicePostById($id: String!, $locale: String!) {
    post: prismicPostDevice(id: { eq: $id }, lang: { eq: $locale }) {
      id
      first_publication_date
      last_publication_date
      data {
        model
        keywords
        vendor {
          document {
            data {
              full_name
            }
          }
        }
        body {
          ... on PrismicPostDeviceBodyWidget {
            id
            slice_type
          }
          ... on PrismicPostDeviceBodyReviews {
            id
            slice_type
            items {
              author
              date_reviewed
              rating
              review {
                html
                text
              }
            }
          }
          ... on PrismicPostDeviceBodyText {
            slice_type
            id
            primary {
              text {
                raw {
                  spans {
                    data {
                      url
                      target
                      link_type
                    }
                    end
                    start
                    type
                  }
                  text
                  type
                }
              }
            }
          }
          ... on PrismicPostDeviceBodyPreface {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicPostDeviceBodyImage {
            slice_type
            id
            primary {
              image {
                url
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                    sizes(maxWidth: 700) {
                      ...GatsbyImageSharpSizes_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    generators: allPrismicGenerator(filter: { uid: { eq: "device" }, lang: { eq: $locale } }) {
      edges {
        node {
          data {
            formula
            formula_description
            formula_h1
            formula_slug
          }
          uid
        }
      }
    }
  }
`

// PostDevice.query = pageQueryCountry

export default PostDevice
